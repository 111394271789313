import { useListen } from '@wemade-vsf/composables'
import { useCustomerStore } from '@wemade-vsf/customer'
import type { Product } from '@wemade-vsf/catalog/lib/runtime/product/types';
import { getPrice, getName, getProductSku, getProductThumbnailImage } from '@wemade-vsf/catalog/lib/runtime/product/getters/productGetters'
import type { ProductInterface } from '@wemade-vsf/magento-api'

declare global {
  interface Window {
    klaviyo: {
      track: (event: string, item: any) => void
      trackViewedItem: (item: any) => void
      identify: (customer: any) => void
    }
  }
}

// @ts-ignore
!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();

export default (context, _inject) => {
  if (!window.klaviyo) {
    console.warn('Klaviyo not found')
    return
  }
  const klaviyo = window.klaviyo || { track: () => { }, trackViewedItem: () => { }, identify: () => {} }

  const customerStore = useCustomerStore(context.$pinia)
  const baseUrl = context.i18n.__baseUrl

  useListen('product:view', (args) => {
    if (!args?.product) return
    const product = args.product as ProductInterface
    const price = getPrice(product)
    if (!context.route.query._kx) return
    const item = {
      "ProductName": getName(product),
      "ProductID": window.atob(product.uid),
      "SKU": getProductSku(product as Product),
      "Categories": product.categories.map(cat => cat.name),
      "ImageURL": getProductThumbnailImage(product as Product),
      "URL": context.route.path,
      "Brand": product.wm_custom_attributes.find(item => item.attribute_code === 'produsent')?.value,
      "Price": price.special ? price.special : price.regular,
      "CompareAtPrice": price.regular
    }
    klaviyo.track('Viewed Product', item)
    klaviyo.trackViewedItem({
      Title: item.ProductName,
      ItemId: item.ProductID,
      Categories: item.Categories,
      ImageUrl: item.ImageURL,
      URL: item.URL,
      Metadata: {
        Brand: item.Brand,
        Price: item.Price,
        CompareAtPrice: item.CompareAtPrice
      }
    })
  })

  useListen('cart:addItem', (args) => {
    if (!args?.product) return
    const product = args.product as ProductInterface
    const quantity = args?.quantity ?? 1
    const price = getPrice(product)
    const usedPrice = price.special ? price.special : price.regular
    const payload = {
      "AddedItemProductName": getName(product),
      "AddedItemProductID": window.atob(product.uid),
      "AddedItemSKU": getProductSku(product as Product),
      "AddedItemCategories": product.categories.map(cat => cat.name),
      "AddedItemImageURL": getProductThumbnailImage(product as Product),
      "AddedItemURL": baseUrl + context.localePath(`/${product?.url_rewrites?.[0]?.url ?? product.url_key}`),
      "AddedItemPrice": usedPrice,
      "AddedItemQuantity": quantity,
      "CheckoutURL": baseUrl + '/checkout/konto',
    }
    klaviyo.track('Added To Cart', payload)
  })

  useListen('user:afterLogin', () => {
    const customer = customerStore.user
    if (!customer?.email) return
    klaviyo.identify({
      "$email": customer.email,
      "$first_name": customer.firstname ?? '',
      "$last_name": customer.lastname ?? '',
    })
  })
}
