import { render, staticRenderFns } from "./SfGallery.vue?vue&type=template&id=70677aea&"
import script from "./SfGallery.vue?vue&type=script&lang=js&"
export * from "./SfGallery.vue?vue&type=script&lang=js&"
import style0 from "./SfGallery.vue?vue&type=style&index=0&id=70677aea&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports