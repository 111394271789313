





































































import {
  defineComponent,
  computed,
  onMounted,
  onBeforeUnmount
} from '@nuxtjs/composition-api';
import { useSearch } from '@wemade-vsf/search-loop54';
import { useUiState } from '@wemade-vsf/composables';
import { useHeaderDesignStore } from '~/stores/useHeaderDesign';
import { storeToRefs } from 'pinia';
import { SfOverlay, SfButton } from '@storefront-ui/vue';
import CartNavigation from './Header/CartNavigation.vue';
import MegaMenu from 'components/megamenu/MegaMenu.vue';
import SearchBar from 'components/search-loop54/SearchBar.vue';

export default defineComponent({
  name: 'AppHeader',
  components: {
    SfOverlay,
    SfButton,
    MegaMenu,
    CartNavigation,
    SearchBar,
    SearchResults: () => import(/* webpackPrefetch: true */ 'components/search-loop54/SearchResults.vue'),
    MegaMenuSidebar: () => import('components/megamenu/MegaMenuSidebar.vue')
  },
  setup() {
    const headerDesignStore = useHeaderDesignStore();
    const {
      isTop,
      scrollDirection
    } = storeToRefs(headerDesignStore);
  
    const {
      isSearchOpen,
      closeSearch
    } = useSearch();

    const {
      isMobileMenuOpen,
      toggleMobileMenu
    } = useUiState();


    // let lastScrollPostion = 0;
    // const handleScroll = () => {
    //   const scrollPosition = window.scrollY;
    //   if (scrollPosition === 0) {
    //     lastScrollPostion = 0;
    //     isTop.value = true;
    //     return;
    //   }
    //   isTop.value = false;
    //   scrollDirection.value = scrollPosition > lastScrollPostion ? 'down' : 'up';
    //   lastScrollPostion = scrollPosition;
    // }

    const dynamicClasses = computed(() => {
      return {
        'is-top': isTop.value,
        'pinned': !isTop.value && scrollDirection.value === 'up',
        'unpinned': !isTop.value && scrollDirection.value === 'down'
      }
    })

    let lastScrollPostion = 0;
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition === 0) {
        lastScrollPostion = 0;
        isTop.value = true;
        return;
      }
      isTop.value = false;
      scrollDirection.value = scrollPosition > lastScrollPostion ? 'down' : 'up';
      lastScrollPostion = scrollPosition;
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });
    return {
      dynamicClasses,
      isSearchOpen,
      isMobileMenuOpen,
      toggleMobileMenu,
      closeSearch
    };
  },
});
