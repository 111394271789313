const moduleOptions = `{"generateRoutes":{"detail":true,"list":false},"baseRoute":"referanser","cmsPage":"referanser"}`;
var plugin_default = (context, inject) => {
  const options = JSON.parse(moduleOptions);
  let wm = context.$wm || {};
  wm.projects = options;
  inject("wm", wm);
};
export {
  plugin_default as default
};
