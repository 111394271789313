export default (context) => {
  const { app, store } = context

  const originalLocalePath = context.localePath;
  const originalLocaleRoute = context.localeRoute;
  const localePath = (route, locale) => {
    const path = originalLocalePath(route, locale) || '';
    return path.replaceAll('%2F', '/');
  }

  const localeRoute = (route, locale) => {
    const resolvedRoute = originalLocaleRoute(route, locale) || '';
    const path = resolvedRoute.path.replaceAll('%2F', '/')
    const fullPath = resolvedRoute.fullPath.replaceAll('%2F', '/')
    let slug = false
    if (resolvedRoute.params['slug']) {
      slug = resolvedRoute.params.slug.replaceAll('%2F', '/')
    }
    return {
      ...resolvedRoute,
      path,
      fullPath,
      params: {
        ...resolvedRoute.params,
        ...slug ? { slug } : {}
      }
    }
  }
  context.localePath = app.localePath = localePath;
  context.localeRoute = app.localeRoute = localeRoute;

  if (store) {
    store.localePath = localePath;
    store.localeRoute = localeRoute;
  }
}