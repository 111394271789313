<template>
  <li class="sf-list__item">
    <slot />
  </li>
</template>
<script>
export default {
  name: "SfListItem",
};
</script>
