




























































































































import {
  defineComponent,
  computed,
  useFetch,
  ref,
  useContext,
  useRoute,
  watch,
  nextTick
} from '@nuxtjs/composition-api';
import { useMegaMenuStore } from '@wemade-vsf/megamenu';
import { SfButton } from '@storefront-ui/vue';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';
import { useImage } from '@wemade-vsf/composables';
import { decodeUid } from '@wemade-vsf/core';
import type { MegaMenuItemTree } from '@wemade-vsf/megamenu';

type MegaMenuItem = MegaMenuItemTree & {
  children: MegaMenuItem[];
  hasChildren: boolean;
  component: string;
  componentProps: Record<string, any>;
}

export default defineComponent({
  name: 'MegaMenu',
  props: {
    isVertical: {
      type: Boolean,
      default: false
    }
  },
  emits: ['open'],
  components: {
    SfButton
  },
  setup (props, { emit }) {
    const { localePath } = useContext();
    const menuStore = useMegaMenuStore();

    const { getMagentoImage } = useImage();

    function addItemProps(item: MegaMenuItemTree, level: number = 0): MegaMenuItem {
      const isClickable = item.children?.length > 0 && level === 0;
      const isInternal = ['category', 'product', 'page', 'internal'].includes(item.link_type);
      let component = 'span';
      if (isClickable) {
        component = 'button';
      } else if (isInternal) {
        component = 'nuxt-link';
      } else if (item.link) {
        component = 'a';
      }

      let componentProps: Record<string, any> = {};
      switch (component) {
        case 'nuxt-link':
          componentProps['to'] = localePath(`${item.link.startsWith('/') ? '' : '/'}${item.link}`)
          break;
        case 'a':
          componentProps['href'] = item.link;
          if (!isInternal && item.target === '_blank') {
            componentProps['target'] = '_blank';
            componentProps['rel'] = 'noopener noreferrer';
          }
          break;
        default:
          break;
      } 
      let iconUrl = null;
      if (item.icon_url) {
        const matchDirective = item.icon_url.match(/(___directive\/)([a-zA-Z0-9,_-]+)/);
        if (matchDirective?.length > 2) {
          const directive = decodeUid(
            matchDirective[2]
              .replace('-', '+')
              .replace('_', '/')
              .replace(',', '=')
          );
          if (directive) {
            const matches = directive.match(/{{media url="([^"]+)"}}/);
            if (matches?.length > 1) {
              iconUrl = matches[1];
            }
          }
        }
      }
      return {
        ...item,
        icon_url: iconUrl ? getMagentoImage(iconUrl) : null,
        children: item.children?.length > 0 ? item.children.map((item) => addItemProps(item, level + 1)) : [],
        hasChildren: item.children?.length > 0,
        component,
        componentProps
      }
    }
    const items = computed(() => {
      if (!menuStore.items?.length) {
        return []
      }
      return menuStore.items.map((item) => addItemProps(item))
    })

    const activeCategory = ref<MegaMenuItem | null>(null)
    const activeSubCategory = ref<MegaMenuItem | null>(null)

    const openMenu = (item) => {
      if (activeCategory.value?.items_id === item.items_id) {
        closeMenu();
      } else {
        activeCategory.value = item
        emit('open')
        lock();
      }
    }

    const openSubmenu = (e: Event, item) => {
      e.preventDefault();
      e.stopPropagation();
      activeSubCategory.value = item
    }

    const closeSubmenu = () => {
      activeSubCategory.value = null
    }

    const closeMenu = () => {
      activeCategory.value = null
      activeSubCategory.value = null
      nextTick(() => {
        clearBodyLocks();
      })
    }

    useFetch(async() => {
      if (items.value.length === 0) {
        await menuStore.load()
      }
    });

    const route = useRoute();
    watch(() => route.value, () => {
      closeMenu()
    })

    return {
      items,
      openMenu,
      closeMenu,
      openSubmenu,
      closeSubmenu,
      activeCategory,
      activeSubCategory
    }
  }
})
