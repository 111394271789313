import { useListen } from "@wemade-vsf/composables";
import { getItemPrice } from "@wemade-vsf/checkout/lib/runtime/getters/cartGetters";
const loop54EventsPlugin = (context) => {
  useListen("cart:addItem", (args) => {
    if (!args || !args.product || !context.app.$loop54)
      return;
    context.app.$loop54.createEvent("addtocart", {
      type: "Product",
      id: args.product.sku
    });
  });
  useListen("loop54:result:click", (args) => {
    if (!args || context.app.$loop54)
      return;
    context.app.$loop54.createEvent("click", {
      type: "Product",
      id: args
    });
  });
  useListen("order:placed", (args) => {
    if (!args || !context.app.$loop54)
      return;
    const order = args;
    order.items.forEach((item) => {
      var _a;
      context.app.$loop54.createEvent("purchase", {
        type: "Product",
        id: item.product_sku
      }, order.number, item.quantity_ordered, (_a = item.product_sale_price) == null ? void 0 : _a.value);
    });
  });
  useListen("order:placed:fromCart", (args) => {
    if (!args || !context.app.$loop54)
      return;
    const { orderNumber, cart: orderCart } = args;
    if (!orderCart || !orderNumber)
      return;
    orderCart.items.forEach((item) => {
      context.app.$loop54.createEvent("purchase", {
        type: "Product",
        id: item.product.sku
      }, orderNumber, item.quantity, getItemPrice(item).final);
    });
  });
};
var eventsPlugin_default = loop54EventsPlugin;
export {
  eventsPlugin_default as default
};
