import formatCurrency from "@wemade-vsf/core/lib/runtime/helpers/formatCurrency";
const plugin = (context, inject) => {
  inject("fc", (value, locale, options = {}) => {
    var _a, _b, _c, _d;
    locale = (locale || ((_b = (_a = context.i18n) == null ? void 0 : _a.localeProperties) == null ? void 0 : _b.iso) || "").replace("_", "-");
    options = { currency: context.app.$vsf.$magento.config.state.getCurrency() || ((_d = (_c = context.i18n) == null ? void 0 : _c.localeProperties) == null ? void 0 : _d.defaultCurrency), ...options };
    return formatCurrency(value, locale, options);
  });
};
var fc_default = plugin;
export {
  fc_default as default
};
