










































import {
  defineComponent,
  useContext,
  computed,
  ref,
  watch,
  onMounted
} from '@nuxtjs/composition-api';
import { useUser } from '@wemade-vsf/customer';
import { useImage, useUiNotification } from '@wemade-vsf/composables';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { SfHeading, SfButton } from '@storefront-ui/vue';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'NewsletterForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    mimage: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 500
    },
    mheight: {
      type: Number,
      default: 668
    },
    buttonColor: {
      type: String,
      default: ''
    },
    buttonPosition: {
      type: String,
      default: 'right'
    },
    layout: {
      type: String,
      default: 'default'
    },
    placeholder: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  components: {
    SfHeading,
    SfButton,
    ValidationObserver,
    ValidationProvider
  },
  setup (props) {
    const { app, i18n } = useContext();
    const { getMagentoImage } = useImage();

    const { user, isAuthenticated, load } = useUser();
    const email = ref('')
    const reSubMinutesLeft = ref(0)
    const isSubscribed = ref(false)

    const { send } = useUiNotification();
    const subscribe = async () => {
      if (!email.value) return;
      const result = await fetch('/ext/newsletter/subscribe?email=' + encodeURIComponent(email.value), {
        method: 'POST'
      });
      const json = await result.json();
      if (json?.minutesLeft) {
        reSubMinutesLeft.value = json.minutesLeft;
        return;
      }

      if (json?.status) {
        isSubscribed.value = true;
        send({
          type: 'success',
          message: i18n.t('You have been successfully subscribed to our newsletter'),
          icon: 'success',
          id: Symbol('signUpNewsletter'),
          persist: false
        })
      }
    }

    const createBackgroundStyles = () => {
      let style = {}
      if (props.image) {
        const imageUrl = app.$img(getMagentoImage(props.image));
        style['--_background-image'] = `url(${imageUrl})`;
        style['--_background-image-mobile'] = `url(${imageUrl})`;
        style['--_bannerHeightDesktop'] = `${props.height}px`;
        style['--_bannerHeightMobile'] = `${props.mheight}px`;
      }
      if (props.mimage) {
        const mImageUrl = app.$img(getMagentoImage(props.mimage));
        style['--_background-image-mobile'] = `url(${mImageUrl})`;
      }

      return style;
    }

    const buttonStyle = computed(() => {
      if (props.buttonColor) {
        return {
          background: props.buttonColor
        }
      }
      return {}
    })

    watch(() => isAuthenticated.value, async (newValue) => {
      if (!user.value) {
        await load()
        if (user.value?.email) {
          email.value = user.value.email;
        }
      }
    })

    onMounted(async() => {
      if (isAuthenticated.value && !user.value) {
        await load()
      }
      if (user.value?.email) {
        email.value = user.value.email;
      }
    })

    return {
      backgroundStyles: createBackgroundStyles(),
      email,
      isSubscribed,
      subscribe,
      buttonStyle,
      reSubMinutesLeft
    }
  }
})
