






















import {
  defineComponent,
  watch,
  useRoute,
  useContext,
  onMounted,
  useRouter,
  ref,
} from '@nuxtjs/composition-api';
import { useSearch } from '@wemade-vsf/search-loop54';

export default defineComponent({
  name: 'SearchBar',
  props: {
    itemsPerPage: {
      type: Number,
      default: 16,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
    openOnFocus: {
      type: Boolean,
      default: true
    },
    focusOnView: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { app } = useContext();
    const searchInput = ref<HTMLInputElement | null>(null);

    const {
      search,
      setSearchTerm,
      isSearchOpen,
      term,
      redirect,
      openSearch,
      closeSearch
    } = useSearch();

    const route = useRoute();

    const toggleSearch = () => {
      if (isSearchOpen.value) {
        openSearch();
      } else {
        closeSearch();
      }
    }

    function doCloseSearch() {
      closeSearch();
      emit('close');
    }

    const rawHandleSearch = async () => {
      if (term.value.length < props.minTermLen) return;
      await search();
    }

    let searchTimeout: any = null;
    const debouncedHandleSearch = () => {
      const searchTerm = searchInput.value?.value ?? '';
      setSearchTerm(searchTerm)
      if (!props.openOnFocus) {
        if (searchTerm && !isSearchOpen.value) {
          openSearch()
        } else if (!searchTerm) {
          closeSearch()
        }
      }
      if (searchTimeout) {
        clearTimeout(searchTimeout);
        searchTimeout = null
      }
      searchTimeout = setTimeout(rawHandleSearch, 500)
    }

    const handleKeydownEnter = (searchTerm: string) => {
      setSearchTerm(searchTerm)
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      if (searchTimeout) {
        clearTimeout(searchTimeout);
        searchTimeout = null
      }
      rawHandleSearch();
    };

    function maybeOpenSearch () {
      if (props.openOnFocus) {
        openSearch()
      }
    }

    onMounted(() => {
      if (props.focusOnView && searchInput.value) {
        searchInput.value.focus();
      }
    })

    watch(route, () => {
      closeSearch();
    });

    const router = useRouter();
    watch(() => redirect.value, (redirect) => {
      if (redirect && redirect.startsWith('http')) {
        // @ts-ignore
        if (redirect.includes(app.i18n.__baseUrl)) {
          //@ts-ignore
          const localePath = app.localePath(redirect.replace(app.i18n.__baseUrl, ''));
          router.push(localePath.startsWith('/') ? localePath : `/${localePath}`);
        } else {
          window.location.href = redirect;
        }
      }
    });

    return {
      doCloseSearch,
      maybeOpenSearch,
      searchInput,
      isSearchOpen,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
    };
  }  
})
