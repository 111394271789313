import { useListen } from "@wemade-vsf/composables";
import cartGetters from "@wemade-vsf/checkout/lib/runtime/getters/cartGetters";
import productGetters from "@wemade-vsf/catalog/lib/runtime/product/getters/productGetters";
import { Logger } from "@wemade-vsf/core";
function buildCartItem(item) {
  var _a;
  return {
    ...item,
    product: {
      ...item.product,
      ...[(_a = item.configured_variant) != null ? _a : {}]
    }
  };
}
function getCartProductInfo(item) {
  item = buildCartItem(item);
  const price = cartGetters.getItemPrice(item);
  return {
    item_id: item.product.sku || "",
    item_name: cartGetters.getItemName(item),
    price: price.regular,
    discount: price.special ? price.regular - price.special : 0,
    quantity: item.quantity || 0
  };
}
function getProductInfo(product) {
  var _a;
  let data = {
    item_id: product.sku || "",
    item_name: product.name || "",
    price: 0,
    discount: 0,
    item_variant: "",
    quantity: 1
  };
  let price;
  if ((_a = product.configurable_product_options_selection) == null ? void 0 : _a.variant) {
    price = productGetters.getPrice(product.configurable_product_options_selection.variant);
    data.price = price.regular;
    data.discount = price.special ? price.regular - price.special : 0;
    data.item_variant = product.configurable_product_options_selection.variant.sku || "";
  } else {
    price = productGetters.getPrice(product);
    data.price = price.regular;
    data.discount = price.special ? price.regular - price.special : 0;
  }
  return data;
}
function plugin_default(context) {
  useListen("*", (type, args) => {
    var _a, _b, _c, _d, _e;
    Logger.debug("ga/event", type, args);
    const gtm = context.app.$gtm || { push: function() {
    } };
    try {
      switch (type) {
        case "user:afterLogin":
          gtm.push({ event: "login" });
          break;
        case "user:register":
          gtm.push({ event: "sign_up" });
          break;
        case "cart:view":
          const cart = args;
          if (!cart)
            return;
          const payload = {
            currency: context.app.$vsf.$magento.config.state.getCurrency(),
            value: ((_a = cart.prices) == null ? void 0 : _a.subtotal_including_tax) || 0,
            items: ((_b = cart.items) == null ? void 0 : _b.map((item2) => getCartProductInfo(item2))) || []
          };
          gtm.push({ event: "view_cart", ...payload });
          break;
        case "cart:addItem":
          if (!args || !args.product)
            return;
          const addedItem = getProductInfo(args.product);
          gtm.push({
            event: "add_to_cart",
            currency: context.app.$vsf.$magento.config.state.getCurrency(),
            value: addedItem.price,
            items: [addedItem]
          });
          break;
        case "cart:removeItem":
          if (!args || !args.product)
            return;
          const item = getCartProductInfo(args.product);
          gtm.push({
            event: "remove_from_cart",
            currency: context.app.$vsf.$magento.config.state.getCurrency(),
            value: item.price,
            items: [item]
          });
          break;
        case "products:list":
          if (!args || !args.products)
            return;
          gtm.push({
            event: "view_item_list",
            items: args.products.map((p, index) => {
              return {
                ...getProductInfo(p),
                index
              };
            })
          });
          break;
        case "product:view":
          if (!args || !args.product)
            return;
          let viewProduct = getProductInfo(args.product);
          gtm.push({
            event: "view_item",
            currency: context.app.$vsf.$magento.config.state.getCurrency(),
            value: viewProduct.price,
            items: [
              viewProduct
            ]
          });
          break;
        case "order:placed:fromCart":
          const { orderNumber, cart: orderCart } = args;
          if (!orderCart || !orderNumber)
            return;
          const orderTotals = cartGetters.getTotals(orderCart);
          const placeOrderPayload = {
            currency: context.app.$vsf.$magento.config.state.getCurrency(),
            transaction_id: orderNumber,
            value: orderTotals.total,
            coupon: ((_c = orderCart.applied_coupons) == null ? void 0 : _c.length) ? orderCart.applied_coupons.map((c) => c.code).join(", ") : null,
            shipping: cartGetters.getShippingPrice(orderCart, true),
            tax: (_d = orderTotals.total_tax) != null ? _d : 0,
            items: ((_e = orderCart.items) == null ? void 0 : _e.map((item2) => getCartProductInfo(item2))) || []
          };
          gtm.push({ event: "purchase", ...placeOrderPayload });
          break;
        default:
          break;
      }
    } catch (e) {
      Logger.debug("Error when trying to send event", e);
    }
  });
}
export {
  plugin_default as default
};
