var render = function (_h,_vm) {var _c=_vm._c;return _c('ol',_vm._b({class:[_vm.data.class, _vm.data.staticClass, 'sf-bullets'],style:([_vm.data.style, _vm.data.staticStyle])},'ol',_vm.data.attrs,false),[_vm._l((_vm.$options.inactiveLeft(_vm.props.total, _vm.props.current)),function(_,index){return [_vm._t("inactive",function(){return [_c('li',{key:index},[_c(_vm.injections.components.SfButton,{tag:"component",staticClass:"sf-button--pure sf-bullet",attrs:{"type":"button","aria-label":'Go to slide ' + (index + 1),"data-testid":'bullet-' + (index + 1)},on:{"click":function($event){_vm.listeners.click && _vm.listeners.click(index)}}})],1)]},null,{ index: index, $options: _vm.$options })]}),_vm._v(" "),_vm._t("active",function(){return [_c('li',[_c(_vm.injections.components.SfButton,_vm._b({tag:"component",staticClass:"sf-button--pure sf-bullet is-active",attrs:{"aria-label":"Current slide"}},'component',_vm.data.attrs,false))],1)]}),_vm._v(" "),_vm._l((_vm.$options.inactiveRight(_vm.props.total, _vm.props.current)),function(_,index){return [_vm._t("inactive",function(){return [_c('li',{key:_vm.$options.inactiveLeft(_vm.props.total, _vm.props.current) + 1 + index},[_c(_vm.injections.components.SfButton,{tag:"component",staticClass:"sf-button--pure sf-bullet",attrs:{"aria-label":'Go to slide ' +
            (_vm.$options.inactiveLeft(_vm.props.total, _vm.props.current) + 2 + index),"data-testid":'bullet-' +
            _vm.$options.inactiveLeft(_vm.props.total, _vm.props.current) +
            (2 + index)},on:{"click":function($event){_vm.listeners.click &&
              _vm.listeners.click(
                _vm.$options.inactiveLeft(_vm.props.total, _vm.props.current) + 1 + index
              )}}})],1)]},null,{
        index: _vm.$options.inactiveLeft(_vm.props.total, _vm.props.current) + 1 + index,
        $options: _vm.$options,
      })]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }