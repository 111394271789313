import { defineStore } from 'pinia'

type State = {
  headerDesign: string;
  isTop: boolean;
  scrollDirection: 'up'|'down'|null;
}

export const useHeaderDesignStore = defineStore({
  id: 'headerDesign',
  state: (): State => ({
    headerDesign: 'default',
    isTop: true,
    scrollDirection: null
  }),
  actions: {
    setHeaderDesign(design: string) {
      this.headerDesign = design;
    }
  }
})