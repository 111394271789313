














import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import { useConfig } from '@wemade-vsf/composables'

export default defineComponent({
  name: 'HeaderLogo',
  props: {
    logo: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const { config } = useConfig();
    const { app } = useContext();

    const logoSrc = computed(() => {
      return props.logo 
        ? props.logo 
        : app.$wm?.theme?.logo?.src || '/logo.svg'
    })

    const logoWidth = computed(() => {
      return props.width
        ? props.width
        : app.$wm?.theme?.logo?.width || 100
    })

    const logoHeight = computed(() => {
      return props.height
        ? props.height
        : app.$wm?.theme?.logo?.height || 100
    })

    const logoAlt = computed(
      () => config.value.logo_alt || config.value.store_name || ''
    )

    return {
      logoAlt,
      logoSrc,
      logoWidth,
      logoHeight
    }
  }
})
