var render = function (_h,_vm) {var _c=_vm._c;return _c(_vm.injections.components.SfButton,_vm._g(_vm._b({tag:"component",class:[
    _vm.data.class,
    _vm.data.staticClass,
    'sf-button--pure sf-color',
    { 'is-active': _vm.props.selected } ],style:(Object.assign({}, _vm.data.style,
    _vm.data.staticStyle,
    {'--color-background': _vm.props.color})),attrs:{"aria-pressed":_vm.props.selected.toString(),"data-testid":_vm.props.color}},'component',_vm.data.attrs,false),_vm.listeners),[_c('transition',{attrs:{"name":"sf-bounce"}},[_vm._t("badge",function(){return [_c(_vm.injections.components.SfBadge,{tag:"component",staticClass:"sf-color__badge smartphone-only",class:{
          'display-none':
            !_vm.props.hasBadge || (!_vm.props.selected && _vm.props.hasBadge),
        }},[_c(_vm.injections.components.SfIcon,{tag:"component",attrs:{"size":"16px","color":"white","icon":"check","aria-hidden":"true"}})],1)]},null,{ props: _vm.props })],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }